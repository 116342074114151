<template>
  <div>
    <div class='main-page-content'>
      <sch-product-dress-save ref='SchProductSave' @success='getList'/>
      <el-divider content-position='left'>服饰选品</el-divider>

      <el-form :model='searchCondition' inline size='mini'>
        <el-form-item>
          <el-select @change='handleQuery' clearable v-model='searchCondition.state' placeholder='请选择选品状态'>
            <el-option label='上播' value='1'></el-option>
            <el-option label='不上播' value='2'></el-option>
            <el-option label='待定' value='0'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input placeholder='请输入产品名称' @change='handleQuery' v-model='searchCondition.title'
                    clearable />
        </el-form-item>
        <el-form-item>
          <el-input placeholder='品牌' v-model='searchCondition.brand_name' @change='handleQuery' clearable
                    style='width: 200px;' />
        </el-form-item>
        <el-form-item>
          <ProductLeaderSelect ref='refProductLeader' :leader-id.sync='searchCondition.leader_id'
                               leader-type='product-dress'
                               @selected='handleQuery' v-if="userPermissions.indexOf('product_view_all') > -1" />
          <span v-else>{{ userInfo.nickname }} </span>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' icon='el-icon-search' @click='handleQuery'>查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click='reset'>重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button icon='el-icon-upload' v-if="userPermissions.indexOf('lb_kol_dress_selection_import')>-1"
                     @click='openImport=true'>
            导入
          </el-button>
        </el-form-item>
        <el-form-item>
          <export-btn :can-export='userPermissions.indexOf("lb_kol_dress_selection_export")>-1' :lint-title='`导出`'
                      @export='exportExcel' ref='refExportLink'></export-btn>
        </el-form-item>
      </el-form>
      <el-row class='mb8' :gutter='20'>
        <el-col :span='1.5'>
          <el-button v-if='userPermissions.indexOf("lb_kol_dress_selection_create")>-1' icon='el-icon-plus' size='mini'
                     type='primary' @click='handleAddProduct'>添加选品
          </el-button>
        </el-col>
        <el-col :span='1.5'>
          <el-button size='mini'
                     v-if='userPermissions.indexOf("lb_kol_dress_selection_standard")>-1' icon='el-icon-s-shop'
                     @click='handleSelect'>服饰库选择
          </el-button>
        </el-col>
        <el-col :span='1.5'>
          <!--          <el-button size='mini' type='primary'-->
          <!--                     @click='handleState' plain>红人意见-->
          <!--          </el-button>-->
          <el-dropdown @click='handleState(stateOptions[stateActive].value)' @command='commandBtn'
                       v-if='userPermissions.indexOf("lb_kol_dress_selection_update_state")>-1' :disabled='multiple'
                       split-button
                       :type='stateOptions[stateActive].type '
          >
            <i :class='stateOptions[stateActive].icon'></i> {{ stateOptions[stateActive].label }}
            <el-dropdown-menu slot='dropdown'>
              <el-dropdown-item :command='index' v-for='(item,index) in stateOptions' :key='item.value'
                                style='width: 80px'>
                <i :class='item.icon'></i> {{ item.label }}
                <br>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span='1.5'>
          <el-button v-if='userPermissions.indexOf("lb_kol_dress_selection_history")>-1' icon='el-icon-s-order'
                     title='历史选品会' @click="handleHistory('selection')">
            历史选品会
          </el-button>
        </el-col>
        <el-col :span='1.5'>
          <el-button v-if='userPermissions.indexOf("lb_kol_dress_selection_history")>-1' icon='el-icon-s-order'
                     title='历史直播' @click="handleHistory('live')">
            历史直播
          </el-button>
        </el-col>
        <el-col :span='1.5'>
          <el-button v-if='userPermissions.indexOf("lb_kol_dress_selection_delete")>-1' icon='el-icon-delete'
                     title='批量删除' @click='handleDeletes()'>
            批量删除
          </el-button>
        </el-col>
        <el-col :span='1.5'>
          <el-button icon='el-icon-setting'
                     @click='customColumnBtn'>列设置
          </el-button>
        </el-col>
      </el-row>
      <!--        选品库-->
      <div class='default-table' v-loading='loading' element-loading-text='加载中……'
           v-if='userPermissions.indexOf("lb_kol_dress_selection_list")>-1' element-loading-spinner='el-icon-loading'>
        <MSetTable :tableData='dataList' row-key='id' @selection-change='handleSelectRows'
                   ref='productMultipleTable' @editCellVal='editCellVal'
                   @row-contextmenu='openMenu'
                   :columns='columns' highlight-current-row border>
          <el-table-column type='selection' width='55' align='center' slot='first-column'></el-table-column>
          <!--        -->
          <!--        <el-table-column type='index' label='序号' width='55' align='center' slot='second-column'></el-table-column>-->
          <el-table-column width='55' align='center' slot='second-column'>
            <template>
              <el-tag class='move-column' effect='plain' style='cursor: move;'>
                <span class='el-icon-rank' style='width: 1em; height: 1em;' /></el-tag>
            </template>
          </el-table-column>

        </MSetTable>
        <el-divider content-position='left'>个性化表格：1）支持行列设置 2）双击单元格复制文本到剪切板 </el-divider>

        <div style='text-align: right'>
          <pagination v-show='total>0'
                      :total.sync='total'
                      :page.sync='pageData.current_page'
                      :limit.sync='pageData.page_size'
                      @pagination='getList' />
        </div>
      </div>
      <div>
        <br />
        <el-row class='mb8' :gutter='15'>
          <el-col :span='1.5'>
            <el-button size='mini' type='primary'
                       icon='el-icon-refresh' @click='getList' plain>刷新列表
            </el-button>
          </el-col>
          <el-col :span='2'>
            <div style='display: inline-block;margin-left: 10px'>
              <div class='selected-total'>已选：
                <span style='color: #ff3176;font-size: 1.4em;font-style: italic;'>{{ ids.length }}</span> 个
              </div>
            </div>
          </el-col>
        </el-row>
      </div>


      <AddDressAck :custom_type='custom_type' ref='addDress' @handelStore='handelStore' :visible.sync='open'
                   append-to-body :formStore.sync='formStore'></AddDressAck>
      <!--    历史排期-->
      <HistoricalSchedule :visible.sync='historicalDialog' :lb-id='dressRow.id'
                          :historicalType='historicalType' append-to-body @close='getList'></HistoricalSchedule>

      <!--    服饰产品库选择-->
      <DressTable ref='ChooseDress' :dressRow='dressRow' @close='getList' append-to-body
                  :visible.sync='DressTableVisible'></DressTable>

      <!--    右键菜单-->
      <Contextmenu :isLog='false' :menu-data='ContextMenuData' :menu-list='menuList' :id='currentId'
                   @change='handleCmd' />
      <!--    列设置-->
      <MColumnSetting :visible.sync='customColumnShow' ref='columnSetting' @userChange='columnSettingChange'
                      :editable-cols='editableCols' :column='userColumns'
                      :table-name='tableName' @back='columnSettingBack'
                      @save='columnSettingSave'></MColumnSetting>

      <!--    导入选品-->
      <import-select-dress :id='dressRow.id' :visible.sync='openImport' @imported='handleQuery'
                           v-if='userPermissions.indexOf("lb_kol_dress_selection_import")>-1'/>
    </div>
    <!--  </el-dialog>-->
  </div>
</template>

<script>
import ProductImage from '@/pages/lb/product/ProductImage'
import CategoryCascader from '@/pages/category/components/CategoryCascader'
import { mapGetters } from 'vuex'
import LeaderSearch from '@/pages/lb/components/LeaderSearch'
import _, { omitBy } from 'lodash'
import AddDressAck from '@/pages/lb/dress/AddDressAck.vue'
import HistoricalSchedule from '@/pages/lb/dress/HistoricalSchedule'
import DressTable from '@/pages/lb/dress/DressTable.vue'
import ExportBtn from '@/components/export/ExportBtn'
import MSetTable from '@/components/common/MSetTable'
import MColumnSetting from '@/components/common/MColumnSetting'
import Contextmenu from '@/pages/lb/product/Contextmenu'
import ImportSelectDress from '@/pages/lb/dress/components/ImportSelectDress'
import Sortable from 'sortablejs'
import ProductDressStore from '@/pages/lb/dress/store.vue'
import ProductLeaderSelect from '@/pages/lb/components/ProductLeaderSelect.vue'
import SchProductDressSave from '@/pages/lb/components/ScheduleProductDressSave.vue'

export default {
  name: 'dressSelectionTable',
  components: {
    SchProductDressSave,
    ProductLeaderSelect,
    ProductDressStore,
    ImportSelectDress,
    LeaderSearch,
    CategoryCascader,
    ProductImage,
    AddDressAck,
    HistoricalSchedule,
    DressTable,
    ExportBtn, MSetTable, MColumnSetting, Contextmenu, Sortable
  },
  computed: {

    ...mapGetters(['userPermissions', 'userInfo'])

  },
  props: {
    dressInfo: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      showLog: false,
      dataList: [],
      total: 0,
      pageData: { page_size: 100, current_page: 1 },
      searchCondition: { category_ids: null, title: null, leader_name: null, leader_id: null, brand_id: null },
      searchConditionLeaderName: null,
      loadingBrandStatus: false,
      brandOptions: [],
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      open: false,
      formStore: {},
      custom_type: 'add',
      historicalDialog: false,
      id: 1,
      stateVisible: false,
      statesData: [],
      DressTableVisible: false,
      stateOptions: [
        { label: '上播', value: 1, color_value: '#67C23A', class_name: 'status-new' },
        { label: '不上播', value: 2, color_value: '#909399', class_name: 'status-launch' },
        { label: '待定', value: 0, color_value: '#409EFF', class_name: 'status-original' }
      ],
      stateActive: 0,
      dressRow: {},
      ContextMenuData: {
        visible: false,
        top: 0,
        left: 0
      },
      menuList: {
        edit: false,
        log: false,
        del: false,
        copy: false
      },
      currentId: '',
      customColumnShow: false,
      tableName: 'lb_dress_kol',
      editableCols: [],
      userColumns: [
        { hide: false, prop: 'product_updated_at', label: '产品最后更新', width: 140, sortable: false, fixed: false },
        {
          hide: false,
          prop: '_state',
          label: '红人意见',
          width: 100,
          sortable: false,
          fixed: false,
          editable: false,
          overflow: false
        },
        { hide: false, prop: 'samples', label: '寄样明细', width: 140, overflow: false, editable: true },
        { hide: false, prop: 'remark', label: '备注', width: 120, overflow: false, editable: true },
        { hide: false, prop: 'wares_prep', label: '预售补货', width: 120, overflow: false, editable: true },
        { hide: false, prop: 'wares_exist', label: '现货库存', width: 120, overflow: false, editable: true },
        { hide: false, prop: 'delivery_cycle', label: '发货时效', width: 120, overflow: false, editable: true },
        { hide: false, prop: 'cover_url', label: '产品图片', width: 60, sortable: false, fixed: false, type: 'image' },
        { hide: false, prop: 'title', label: '产品名称', width: 120, overflow: false, editable: true },
        { hide: false, prop: '_wares_standard_is', label: '是否标品', width: 120, overflow: false, editable: false },
        { hide: false, prop: 'brand_name', label: '品牌', width: 120, overflow: false, editable: true },
        { hide: false, prop: 'spec_name', label: '尺码颜色', width: 120, overflow: false, editable: true },
        { hide: false, prop: 'used_season', label: '季节性', width: 120, overflow: false, editable: false },
        { hide: false, prop: 'price', label: '日常价', width: 120, overflow: false, editable: true },
        { hide: false, prop: 'lb_price', label: '直播价', width: 120, overflow: false, editable: true },
        { hide: false, prop: 'gift', label: '赠品', width: 120, overflow: false, editable: true },
        { hide: false, prop: 'commission', label: '佣金', width: 120, overflow: false, editable: true },
        { hide: false, prop: 'support_situation', label: '保价情况', width: 120, overflow: false, editable: true },
        { hide: false, prop: 'company_score', label: '店铺评分', width: 120, overflow: false, editable: true },
        { hide: false, prop: 'contact_info', label: '对接人联系方式', width: 120, overflow: false, editable: true },
        { hide: false, prop: 'leader_name', label: '负责人', width: 120, overflow: false },
        {
          hide: true,
          prop: 'contract_progress',
          label: '合同进度',
          width: 120,
          sortable: false,
          fixed: false,
          editable: true,
          overflow: false
        },
        { hide: true, prop: 'platform_name_alis', label: '可播平台', width: 120, overflow: false },
        { hide: false, prop: 'order', label: '排序值', width: 110, sortable: false, fixed: false, editable: true },
        { hide: true, prop: 'created_at', label: '创建时间', width: 140, sortable: false, fixed: false },
        { hide: true, prop: 'updated_at', label: '修改时间', width: 140, sortable: false, fixed: false }
      ],
      columns: [],
      //导入相关
      openImport: false,
      historicalType: 1,
      newIndexList: []

    }
  },
  methods: {
    reset() {
      this.searchCondition = {}
      this.getList()
    },
    onOpen() {
      // this.dressRow = {
      //   id: this.$route.params.id,
      //   nickname: this.$route.params.nickname,
      //   date: this.$route.params.date,
      //   platform_name: this.$route.params.platform_name
      // }

      this.id = this.$route.params.id
      this.getList()

      this.showLog = false
      // this.dialogTitle = `红人选品会-上(ID:${this.lbId})`
      this.searchCondition = { category_ids: null, title: null, leader_name: null, leader_id: null, brand_id: null }
      this.searchCondition.leader_name = this.userInfo?.nickname

    },
    close() {
      this.$emit('update:visible', false)
    },
    selectedLeader(item) {
      this.searchCondition.leader_id = item.leader_id
      this.handleQuery()
    },
    // 级联菜单组件change事件
    cascaderChange(v) {
      this.searchCondition.category_id = null
      if (v.length) {
        this.searchCondition.category_id = v[(v.length - 1)]
        this.searchCondition.category_ids = v
      } else {
        this.searchCondition.category_id = null
        this.searchCondition.category_ids = null
      }
      this.handleQuery()
    },
    async getBrand(force) {
      this.loadingBrandStatus = true
      if (this.brandOptions.length === 0 || force) {
        // let data = await this.$api.selectCustomerBrands({ customer_id: this.formData.customer_id })
        let { options } = await this.$api.getBrandOptions()
        this.brandOptions = options
        this.loadingBrandStatus = false
      }
    },
    // setSelectBrand(val) {
    //   let option = this.brandOptions.find(value => value.id === val)
    //   this.searchCondition.brand_name = option ? option.name : ''
    // },

    selectable(row) {
      return !row.lb_product_id
    },
    handleQuery() {
      this.dataList = []
      this.pageData.current_page = 1
      this.getList()
    },
    handleSearchCondition() {
      let cond = { ...{}, ...this.searchCondition }
      cond = omitBy(cond, (value) => value === undefined || value === null || value === '')
      return cond
    },
    async getList() {
      this.loading = true
      this.dataList = []
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, { lb_id: this.id }, this.pageData)

      let { list, pages } = await this.$api.getLbDressSelectionList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list || []
        //收集ID
        this.newIndexList = []
        this.dataList.forEach(item => {
          this.newIndexList.push(item.id)
        })
        this.pageData.current_page = pages.current_page || 1
        this.pageData.page_size = pages.page_size || 10
        this.total = pages.total
        this.loading = false
        //触发选中效果
        // this.toggleSelection()
        this.initSortableList()

      })

    },

    handleSelectRows(selection) {
      // this.selectedList = selection || []
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
      this.statesData = selection

    },
    handleCopy(row) {
      this.custom_type = 'add'
      delete row.id
      delete row.state
      delete row._state
      delete row.created_at
      delete row.created_by
      delete row.updated_by
      delete row.updated_at

      this.formStore = row
      this.open = true
    },
    async handleAdd() {
      let postData = {
        lb_id: this.dressRow.id,
        product_ids: this.ids
      }
      await this.$api.appendLbScheduleProducts(postData)
      this.close()
    },
    async saveData(form) {
      let id = await this.$api.saveLbDressSelectionInfo(form)
      if (id) {
        await this.getList()
      }
    },
    //新增-修改保存按钮
    handelStore(val) {
      let _t = this
      this.$refs.addDress.validate().then(() => {
        let saveData = {}
        Object.assign(saveData, { lb_id: this.dressRow.id }, val)
        if (val.cover_url) {
          if (this.custom_type === 'add') {
            //这里是新增的逻辑
            this.dataList.push(val)
            this.open = false
            this.saveData(saveData)

            this.$message.success('新增成功')
          } else {
            //这里是修改的逻辑
            let _index = _.findIndex(this.dataList, function(o) {
              return _t.formStore.id == o.id
            })
            this.dataList[_index] = val
            this.open = false
            this.saveData(saveData)

            this.$message.success('修改成功')
          }
          this.formStore = {}

        } else {
          this.$message.error('产品图必填')
        }
      }).catch(() => {
        this.$message.error('请检查必填信息')
      })
    },
    async callbackAppendProduct(product_id) {
      const res = await this.$api.appendLbDressSelection(this.dressRow.id, [product_id])
      if (res) {
        this.$message.success('添加成功')
        await this.handleQuery()
      }
    },
    handleAddProduct() {
      this.$refs.SchProductSave.show(this.id)
    },
    handleEditRow(row) {
      if (row?.id) {
        this.custom_type = 'edit'
      }
      this.copyRow = {}
      this.formStore = row

      this.open = true
    },
    async delInfo(id) {
      let info = await this.$api.deleteLbDressSelectionInfo(id)
      if (info) {
        this.msgSuccess('删除成功')
        await this.getList()
      }
    },
    handleDelete(row) {
      this.$confirm(`是否确认移除选品【${row.title}/${row.brand_name}】?`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delInfo(row.id)
      }).catch(() => {
        this.msgWarn('取消操作')
      })
    },
    handleHistory(value) {
      this.historicalDialog = true
      this.historicalType = value === 'selection' ? 1 : 2
    },

    handleSelect() {
      this.$refs.ChooseDress.show()
    },
    commandBtn(val) {
      this.stateActive = val
      this.handleState(this.stateOptions[this.stateActive].value)
    },
    async handleState(val) {

      let id = await this.$api.updateLbDressSelectionState(this.ids, val)
      if (id) {
        this.$message.success('修改成功')
        await this.getList()
      }
    },
    async exportExcel() {
      try {
        let name = `${this.dressRow?.nickname} ${this.$utils.parseTime(this.dressRow.start_at, '{y}年{m}月{d}日')} ${this.dressRow?.platform_name}服饰库选品列表`
        let searchCondition = this.handleSearchCondition()
        Object.assign(searchCondition, { table_name: this.tableName, lb_id: this.dressRow.id })
        let response = await this.$api.exportDressSelectList(searchCondition)
        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    handleDeletes() {
      if (this.ids.length != 0) {
        this.$confirm(`是否确认移除${this.ids.length}条选品?`, '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.ids.forEach((i, index) => {
            this.delInfos(i, index)
          })
        })
      } else this.$message.error('请选择删除对象')

    },
    async delInfos(id, index) {
      let info = await this.$api.deleteLbDressSelectionInfo(id)
      if (info && index === this.ids.length - 1) {
        this.$message.success('批量删除成功')
        await this.getList()
      }
    },
    openMenu(row, column, e) {
      this.currentRow = row
      // this.$notify.info(`右键菜单:${e.clientY}`)
      e.preventDefault()//阻止元素发生默认的行为
      // const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      // const offsetTop = 0// this.$el.getBoundingClientRect().top; // container margin top
      this.menuList.edit = this.userPermissions.indexOf('lb_kol_dress_selection_edit') > -1
      this.menuList.del = this.userPermissions.indexOf('lb_kol_dress_selection_delete') > -1
      this.menuList.copy = this.userPermissions.indexOf('lb_kol_dress_selection_create') > -1
      this.ContextMenuData = {
        visible: true,
        left: e.pageX,//- offsetLeft
        top: e.pageY// - offsetTop
      }
      this.currentId = row.id
      document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
    },
    //右键菜单关闭
    closeMenu(e) {
      //未点击在 菜单上，则关闭
      if (e.target.className.indexOf('contextmenu__item') === -1) {
        this.ContextMenuData.visible = false
        document.removeEventListener('click', this.closeMenu)
      }
    }
    ,
    handleCmd(cmd) {
      //1：修改 2：复制 3：删除
      switch (cmd) {
        case '1':
          this.$refs.SchProductSave.show(this.id, this.currentRow)
          break
        case '2':
          this.handleCopy(this.currentRow)
          break
        case '3':
          this.handleDelete(this.currentRow)
          break
      }
    },
    columnSettingChange(userColumn) {
      //设置列-修改表显示
      // this.customColumnShow=false
      this.columns = [...userColumn]
      this.isColSet = true
    },
    // eslint-disable-next-line no-unused-vars
    columnSettingSave(userColumn) {
      this.customColumnShow = false
      this.isColSet = true
    },
    // eslint-disable-next-line no-unused-vars
    columnSettingBack(userColumn) {
      this.customColumnShow = false
      this.isColSet = false
      this.editableCols = []

      this.getDefaultColumns()
    },
    getDefaultColumns() {
      if (localStorage.getItem(`table-column:${this.tableName}`)) {
        const tableSet = JSON.parse(localStorage.getItem(`table-column:${this.tableName}`))
        let userColumn = tableSet.user_columns
        this.columns = [...userColumn]
        this.isColSet = true
      } else {
        this.isColSet = false
        this.columns = [...this.userColumns]
      }
    },
    async editCellVal(row, field) {
      if (row.id && field) {
        if (field === 'sample_date') {
          if (!(row[field] === this.$utils.parseTime(row[field], '{y}-{m}-{d}'))) {
            this.$message.warning('请输入正确日期格式：xxxx-xx-xx')
            row[field] = null
            return false
          }
        }
        await this.$api.cellEditDressSelectionInfo(row.id, field, row[field])
      } else {
        this.$notify.warning('参数错误，无法提交！！！')
      }
    },
    isEditableCols() {
      const editableCols = []
      this.userColumns.map(_ => {
        if (_.editable) {
          editableCols.push(_.prop)
        }
      })
      if (this.userPermissions.indexOf('lb_kol_dress_selection_cell_edit') > -1) {
        this.editableCols = [...this.editableCols, ...editableCols]
      }
    },
    customColumnBtn() {
      this.isEditableCols()
      this.customColumnShow = true
    },
    initSortableList() {
      document.body.addEventListener('drop', (event) => {
        event.preventDefault()
        event.stopPropagation()
      }, false)

      if (this.$refs.productMultipleTable) {
        let el = this.$refs.productMultipleTable.$el.querySelector('.el-table__body-wrapper>.el-table__body >tbody')
        //设置配置
        // let _this = this
        Sortable.create(el, {
          animation: 150,
          sort: true,
          // draggable: '.el-table__row', // 设置可拖拽行的类名(el-table自带的类名)
          handle: '.move-column',
          onEnd: evt => {
            const oldIndex = evt.oldIndex
            const newIndex = evt.newIndex
            let currRow = this.newIndexList.splice(oldIndex, 1)[0]
            this.newIndexList.splice(newIndex, 0, currRow)
            this.dragSortSubmit(newIndex, oldIndex)
          }
        })
      }
    },
    async dragSortSubmit(newIndex, oldIndex) {
      //获取排序index 1:4
      let dragId = this.dataList[oldIndex]['id']
      let dragOrder = this.dataList[newIndex]['order']
      // console.log('drag', dragId, dragOrder)
      let postData = [
        { id: dragId, order: dragOrder }
      ]
      let startIndex = newIndex > oldIndex ? oldIndex : newIndex
      let endIndex = newIndex > oldIndex ? newIndex : oldIndex

      if (newIndex > oldIndex) {
        // console.log(`向下拖拽：${oldIndex}->${newIndex}`)
        for (let i = startIndex + 1; i <= endIndex; i++) {
          let preRow = this.dataList[i - 1]
          let orgRow = this.dataList[i]
          // console.log(`drag ${i}`, { id: orgRow.id, order: preRow.order })
          postData.push({ id: orgRow.id, order: preRow.order })
        }
      } else {
        // console.log(`向上拖拽：${oldIndex}->${newIndex}`)
        for (let i = startIndex; i < endIndex; i++) {
          let preRow = this.dataList[i + 1]
          let orgRow = this.dataList[i]
          // console.log(`drag ${i}`, { id: orgRow.id, order: preRow.order })
          postData.push({ id: orgRow.id, order: preRow.order })
        }
      }
      // this.$notify.info(`您将 第${oldIndex + 1}行 拖拽至 第${newIndex + 1}行`)
      await this.$api.saveDressOrder({ ids: postData })
      setTimeout(async () => {
        await this.getList()
      }, 500)
      // await this.$api.saveScheduleOrder({ ids: this.newIndexList })
    },
  },
  mounted() {
    this.dressRow = this.dressInfo
    // console.log(this.dressRow)
    this.onOpen()
    this.getDefaultColumns()
    this.isEditableCols()

  },
  watch: {
    dressInfo: {
      deep: true,
      immediate: true,
      handler() {
        this.dressRow = this.dressInfo
        // console.log(this.dressRow)
      }
    }
  }
}
</script>
