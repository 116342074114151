<template>
  <div>
    <el-row style='margin: 10px 0;'>
      <el-card class='box-card'>
        <div style='width: 100%;font-size: 24px;font-weight: bold'>
          <div style='position: absolute'>
            <el-link style='font-size: 18px' title='点击打开[美护]' disabled> [服饰]</el-link>
            <el-link type='primary' style='font-size: 18px' title='点击打开[美护]' @click='handleRouter'> [美护]
            </el-link>
          </div>
          <div style='text-align: center;width: 100%'>
            {{ this.$utils.parseTime(dressRow.start_at, '{y}年{m}月{d}日') }} 【{{ dressRow?.nickname }}】
            {{ dressRow?.platform_name }} 服饰选品
          </div>
        </div>
      </el-card>
    </el-row>

    <div class='main-page-content'>
      <dressInfo :dressRow='dressRow' @close='getInfo'></dressInfo>
      <br />
      <br />
      <div v-if='userPermissions.indexOf("lb_dress_demand")>-1'>
        <el-divider content-position='left'>需求明细表</el-divider>
        <div>
          <DemandItemList :lb-id='id' v-if='userPermissions.indexOf("lb_dress_demand_list")>-1' />
        </div>
        <div style='width: 100%;text-align: center;margin-top: 50px'>
          <el-button :type='`${progress_status > 1?"success":"primary"}`' @click='commitDemand' size='medium'
                     icon='el-icon-s-promotion' plain
                     style='width: 200px'
                     :disabled='!canEdit'
                     v-if="userPermissions.indexOf('lb_kol_demand_launch')>-1">
            {{ progress_status > 1 ? '已发起需求' : '发起需求' }}
          </el-button>
        </div>
      </div>
      <br />
      <br />
      <dress-selection-table :dressInfo='dressRow'></dress-selection-table>
      <div v-if='userPermissions.indexOf("lb_kol_dress_selection_log")>-1'>
        <el-divider content-position='left'>
          <el-link type='primary' @click='showLog=!showLog'>日志
            <span :class='[!showLog?"el-icon-arrow-down":"el-icon-arrow-up"]'></span>
          </el-link>
        </el-divider>
        <LbSelectionDressLog v-if='showLog' :info-id='dressRow.id' />
      </div>
    </div>
    <CreateEdit :visible.sync='EditOpen' :id='dressRow.id' @close='getInfo' />
    <!--    发起需求-->
    <CommitDemandConfirm :configType="'dress'" :visible.sync='openDemandConfirm' :id='id' @close='getInfo' />
    <!--    内部服饰选品规则-->
    <RuleArticle></RuleArticle>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LbSelectionDressLog from '@/pages/lb/dress/components/LbSelectionDressLog.vue'
import CreateEdit from '@/pages/lb/kol/CreateEdit.vue'
import dressInfo from '@/pages/lb/dress/dressProduct/dressInfo.vue'
import DressSelectionTable from '@/pages/lb/dress/dressProduct/dressSelectionTable.vue'
import DemandItemList from './DemandItemList.vue'
import CommitDemandConfirm from '@/pages/lb/components/CommitDemandConfirm.vue'
import RuleArticle from '@/pages/lb/dress/dressProduct/RuleArticle'

export default {
  name: 'Index',
  components: {
    RuleArticle,
    CommitDemandConfirm,
    DemandItemList,
    DressSelectionTable,
    CreateEdit,
    LbSelectionDressLog,
    dressInfo
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    canEdit() {
      return (this.userPermissions.indexOf('lb_schedule_edit_leader') > -1 || this.dressRow.leader_id === this.userInfo.id)
    }
  },
  data() {
    return {
      id: null,
      loading: false,
      showLog: false,
      open: false,
      custom_type: 'add',
      EditOpen: false,
      dressRow: {},
      progress_status: 1,
      openDemandConfirm: false

    }
  },
  methods: {
    onOpen() {
      this.getInfo()
      this.showLog = false
      // this.dialogTitle = `红人选品会-上(ID:${this.lbId})`
    },
    close() {
      this.$emit('update:visible', false)
    },
    async getInfo() {
      let { info } = await this.$api.getLbScheduleInfo(this.id)
      this.dressRow = info
      document.title = `【${this.dressRow.nickname}】红人选品`
      this.progress_status = info.progress_status

    },
    handleRouter() {
      const { href } = this.$router.resolve({
        name: 'lbKolDetail',
        params: { id: this.id }
      })
      window.open(href, '_blank')
    },
    async commitDemand() {
      this.openDemandConfirm = true
      // await this.$api.launchLbSchedule(this.id)
      // await this.init()
    },
  },
  mounted() {
    this.id = this.$route.params.id

    this.onOpen()

  }
}
</script>
